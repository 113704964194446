import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {TextField, Typography, Button} from '@material-ui/core';
import FormGroup from '@material-ui/core/FormGroup';
import classNames from 'classnames';

import Utils from '../api/api';
import theme from '../layout/theme';

const styles = {
  hide: {
    display: 'none'
  },
  error: {
    color: theme.palette.error[500]
  },
  container: {
    /* position: 'fixed', */
    zIndex: 10001,
    left: 0,
    right: 0,
    top: 56,
    bottom: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  [theme.breakpoints.up('sm')]: {
    container: {
      top: 64
    }
  },
  form: {
    width: 300,
    margin: '0 auto',
    padding: 30,
    border: 'solid 1px #eee',
    boxShadow: '0 0 15px rgba(0,0,0,0.1)',
    background: '#fff'
  },
  textField: {
    width: '100%'
  },
  textInput: {},
  formRow: {
    justifyContent: 'center',
    marginTop: 25
  }
};

class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      new_password: '',
      verify_password: '',
      username_error: false,
      password_error: false,
      new_password_error: false,
      verify_password_error: false,
      error: false
    };
  }
  componentDidMount() {
    //console.log(this.props);
    // var main = document.querySelector('.main');
    // main.style.height = window.innerHeight - document
    //   .querySelector('header')
    //   .offsetHeight - document
    //   .querySelector('footer')
    //   .offsetHeight + 'px';
  }
  handleValidate(text, e) {
    var obj = {};
    obj[text] = e.target.value;

    //console.log(e.target.value) validate here
    var p = text + '_error';
    if (e.target.value.trim() === '' || e.target.value === null) {
      obj[p] = true;
    } else {
      obj[p] = false;
    }
    this.setState(obj, () => {
      if (!this.state.username_error && !this.state.password_error) {
        obj['error'] = false;
      } else {
        obj['error'] = true;
      }
      this.setState(obj, () => {
        //console.log(this.state);
      });
    });
  }
  handleUpdate(e) {
    //console.log(this.context);
    if (this.state.error) {
      return;
    }
    var $this = this;
    var redirect = this.context.router.route.match.params.url;
    if (redirect !== undefined) {
      if (redirect.indexOf(':') === 0) {
        redirect = redirect.replace(':', '/');
      }
    }
    if (this.state.new_password === this.state.verify_password) {
      Utils
        ._post('users/changepass/',$this.state, function (data) {
          //console.log(data);
          if (data.status === 'success') {
            $this
              .context
              .router
              .history
              .push('/login');
          }
        });
    } else {
      this.setState({verify_password_error: true, error: true});
    }
  }
  render() {
    const classes = this.props.classes;
    return (
      <div className={classes.container}>
        <form className={classes.form} noValidate>
          <Typography type="body2" className={classNames(classes.error, !this.state.error && classes.hide)} >Vui lòng nhập tất cả các field (*)</Typography>
          <TextField id="username" label="Username" className={classes.textField} value={this.state.username} onChange={(event) => this.handleValidate('username', event)} margin="normal" required={true} error={this.state.username_error}/>
          <TextField id="password" label="Password" className={classes.textField} type="password" autoComplete="current-password" margin="normal" value={this.state.password} onChange={event => this.handleValidate('password', event)} required={true} error={this.state.password_error}/>
          <TextField id="new_password" label="New Password" className={classes.textField} type="password" margin="normal" value={this.state.new_password} onChange={event => this.handleValidate('new_password', event)} />
          <TextField id="verify_password" label="Verify New Password" className={classes.textField} type="password" margin="normal" value={this.state.verify_password} onChange={event => this.handleValidate('verify_password', event)} />
          <FormGroup row className={classes.formRow}>
            <Button
              variant="contained"
              color="primary"
              onClick={(event) => this.handleUpdate(event)}>Cập nhật</Button>
          </FormGroup>
        </form>
      </div>
    );
  }
}

ChangePassword.contextTypes = {
  router: PropTypes.object
};

export default withStyles(styles)(ChangePassword);
